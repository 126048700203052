// const VietQR = require("./vietQR");
import VietQR from './VietQR'
const vietQR = new VietQR();

const createBank = (bin) => (consumerID, amount, desc) => {
    vietQR
        .setBeneficiaryOrganization(bin, consumerID)
        .setTransactionAmount(`${amount}`)
        .setAdditionalDataFieldTemplate(desc);

    console.log('vietQR.build()');
    console.log(vietQR.build());
    return vietQR.build();
}

export const createBankTp = (consumerID, amount, desc) => {
    return createBank("970423")(consumerID, amount, desc)
}

export const createBankMb = (consumerID, amount, desc) => {
    return createBank("970422")(consumerID, amount, desc)
}
