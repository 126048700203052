const config = {
  formSupport: {
    url: 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSe1NuQAzIThiZmsa_orQXyNG8zXPJIGP-rBJAixJnHbNqnA7A/formResponse',
    payload: {
      name: "entry.1230813874",
      email: "entry.9676087",
      subject: "entry.1803240528",
      message: "entry.1237467967",
    }
  },
  formBuyProduct: {
    url: 'https://docs.google.com/forms/u/0/d/e/1FAIpQLScUZCg3BZDc5ylfoPG_jSqAUYHLzprhgYgaBdRnAGbmQ16Fbw/formResponse',
    payload: {
      userId: 'entry.904995853',
      name: 'entry.2144837468',
      desc: 'entry.1230813874',
      version: 'entry.9676087',
      device: 'entry.1803240528',
      source: 'entry.1237467967',
      data: 'entry.1768904391',
    }
  },

  linkIos: "https://apps.apple.com/us/app/effortless-english-learning/id6449653897",
  linkAndroid: "https://play.google.com/store/apps/details?id=com.cyf.effortlessEnglish",

  banks: {
    tp: {
      name: 'NGUYEN VAN DUC',
      accoutNumber: '02503880301',
      bankName: 'TPBank'
    },
    mb: {
      name: 'NGUYEN VAN DUC',
      accoutNumber: '3010116669999',
      bankName: 'MBBank'
    },
  },
  productIdToAmount: {
    "50_years": 1799000,
    "15_years": 1699000,
    "5_years": 999000,
    "2_years": 599000,
    "1_year": 449000,
    "1_month": 79000
  },
  firebaseConfigEE: {
    apiKey: "AIzaSyAiUk4GXhyGBoYPAW3wPpKfszMYOeeaHYo",
    authDomain: "effortless-english-8477e.firebaseapp.com",
    projectId: "effortless-english-8477e",
    storageBucket: "effortless-english-8477e.appspot.com",
    messagingSenderId: "184516862390",
    appId: "1:184516862390:web:06eaca4cc5275830f5e117",
    measurementId: "G-22LKWCQ87D"
  },
  firebaseConfigReader: {
    apiKey: "AIzaSyBrPSTHrDEkTUL9i-WUlWgoCdCCaGeH2lM",
    authDomain: "voice-reader-35489.firebaseapp.com",
    projectId: "voice-reader-35489",
    storageBucket: "voice-reader-35489.appspot.com",
    messagingSenderId: "156850034699",
    appId: "1:156850034699:web:b977196b14565b3a058f0e",
    measurementId: "G-6RWZW56FXH"
  },
  discountAff: 0.1,
  affids: [
    "1965579043",
    "803472151"
  ],
  affLink: "https://apir10.effortless-english.com/transaction/api-v1/affiliateId/",
  // affLink: "http://192.168.1.9:3000/transaction/api-v1/affiliateId/",
  affApiGet: "Apikey badKB18Plb4GuBuU7rSKbQGYkL@evP!32Y@bsP!48OESyPB5rdRCzAkg%cJlPnv2"
}



export default config