import React, { useState, useMemo, useEffect } from 'react';
import QRCode from "react-qr-code";
import { useSearchParams, useNavigate } from "react-router-dom";
import config from "../../../config";
import { createBankTp, createBankMb } from "../../../lib/qrBank";
import { formatNumberWithSpaces, copyToClipboardFallback, amountDiscountNumber } from "../../../lib/text";

import './styles.css';

const banks = config.banks;
const productIdToAmount = config.productIdToAmount;
let userIdGlobal = ''

function Payment({ productId, pricingsData, strings, discount }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [type, setType] = useState('mb');
  const [qrText, setQrText] = useState();
  const [productIdState, setProductId] = useState(productId);
  // const [userId, setUserId] = useState('eUgsI76KfycVan1lgr0supacnSc2');
  const [userId, setUserId] = useState('');
  const [textErr, setTextErr] = useState('');
  const [userIdState, setUserIdState] = useState('');
  userIdGlobal = userIdState
  useEffect(() => {
    setProductId(productId)
  }, [productId])


  const isMbBank = useMemo(() => {
    return type === 'mb'
  }, [type])
  const bankInfo = useMemo(() => {
    return banks[type] || banks.tp
  }, [type])

  const amount = useMemo(() => {
    return amountDiscountNumber(productIdToAmount[productIdState] || productIdToAmount['1_month'], discount || 0)
  }, [productIdState, discount])

  const content = useMemo(() => {
    if (!userId) return '';
    const affId = searchParams.get("aid") || 'r10'
    return `${userId.trim()} ${affId.trim()} ee`
  }, [userId])

  const isCorrectUser = useMemo(() => {
    if (!userId) return false;
    return userId.trim().length === 28
  }, [userId])

  const productName = useMemo(() => {
    for (let index = 0; index < pricingsData.length; index++) {
      for (let i = 0; i < pricingsData[index].length; i++) {
        const element = pricingsData[index][i];
        console.log('elementelementelement', element)
        if (element.productId === productIdState) return element.name
      }
    }
    return productIdState
  }, [pricingsData, productIdState])

  console.log('productNameproductName', productIdState, productName)

  const onChangeProductId = (value) => {
    setProductId(value)
  }

  const onChangeUserId = (e) => {
    console.log('onChangeUserId', e.target.value)
    setUserIdState(e.target.value)
    setTextErr('')
  }

  const oncopy = (text) => {
    copyToClipboardFallback(text)
  }

  const onSetUserId = () => {
    if (userIdGlobal) {
      if (userIdGlobal.length !== 28) {
        setUserId('')
        setTextErr('User Id không đúng bạn vui lòng kiểm tra lại cách lấy hoặc liên hệ với chúng tôi để được hỗ trợ nhanh nhất')
      } else {
        setTextErr('')
        setUserId(userIdGlobal)
      }
    } else {
      setUserId('')
      setTextErr('Bạn cần nhập User Id')
    }

  }

  useEffect(() => {
    if (amount) {
      let textCreatBank = ''
      if (type === 'tp') {
        textCreatBank = createBankTp(bankInfo.accoutNumber, amount, content)
      } else {
        textCreatBank = createBankMb(bankInfo.accoutNumber, amount, content)
      }
      setQrText(textCreatBank)
    }
  }, [type, productIdState, userId, amount])

  console.log('pricingsDatapricingsData', pricingsData)

  return (
    <div className="row" style={{ background: 'white', margin: '0 0' }}>
      <div className="viewConInputFrom">
        <div className="viewInputFrom">
          <input aria-label={strings.userIdDesc} autoComplete="off" className="form-control" length={28} name={userIdState} onChange={onChangeUserId}
            placeholder={strings.userIdDesc} required="required" spellCheck="false" type="text" />
          <button className="btn btn-primary" type="button" onClick={onSetUserId}>Xong</button>
        </div>
        {textErr ? <div className="viewErrorInputFrom">
          * {textErr}
        </div> : null}
        {isCorrectUser &&
          <div className="viewInputPackage">
            <div className="viewPackage">
              <span>Gói đang mua: </span>
              <div className="dropdown">
                <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">{productName} <span className="caret"></span></button>
                <ul className="dropdown-menu">
                  {pricingsData.map((pricingData) => pricingData.map(res => {
                    const clazz = `viewSelectItem ${res.productId === productIdState ? 'selectItemActive' : 'selectItemNormal'}`
                    return <li className={clazz} key={res.productId} onClick={() => onChangeProductId(res.productId)}>
                      <span className="selectItem">{res.name}</span>
                    </li>
                  })
                  )}
                </ul>
              </div>
            </div>

            <div className="viewBank">
              <span>Ngân hàng: </span>
              <div onClick={() => setType('mb')} className={`viewImgBank ${isMbBank ? 'viewImgBankActive' : ''}`}>
                <img alt="MB Bank" src="./payment/mbbank.jpg" />
              </div>
              <div onClick={() => setType('tp')} className={`viewImgBank ${!isMbBank ? 'viewImgBankActive' : ''}`}>
                <img alt="MB Bank" src="./payment/tpbank.png" />
              </div>
            </div>
          </div>
        }
      </div>
      {isCorrectUser && <div>
        <div className="viewTextCk">
          <div className="w23">Vui lòng chuyển khoản theo thông tin:
            <br />Chủ tài khoản: <span className="text1">{bankInfo.name}</span>
            <br />Số tài khoản: <span className="text1">{bankInfo.accoutNumber}</span>
            <br />Ngân hàng: <span className="text1">{bankInfo.bankName}</span>
            <br />LƯU Ý: Nội dung chuyển khoản GHI CHÍNH XÁC LÀ
            <br /><span className="textHighlight">{content}</span>cùng số tiền
            <span className="text1">{formatNumberWithSpaces(amount, '.')}đ</span> để hệ thống xác nhận đơn hàng và tự động lên Pro cho giao dịch của bạn. <span className="textBold">(khi bạn chuyển khoản thành công, vui lòng đợi vài giây rồi kiểm tra trạng thái Pro ở trên App của bạn)</span>
          </div>
          <div className="viewQR">
            {qrText ? <div style={{ padding: '2px 5px', maxWidth: 250, width: "100%", background: 'white' }}>
              <QRCode
                size={500}
                style={{ height: "100%", width: "100%" }}
                value={qrText}
                viewBox={`0 0 500 500`}
              />
            </div> : null}
          </div>
        </div>
      </div>}
    </div>
  );
}

export default Payment;
