import React, { useState, useMemo, useEffect } from 'react';
import QRCode from "react-qr-code";
import config from "../../../config";
import './styles.css';

const linkIos = config.linkIos;
const linkAndroid = config.linkAndroid;
const typeQr = {
  android: 'android',
  ios: 'ios'
}

function TutGetUserId({ strings, onClose, onGoAppleStore, onGoGoogleStore }) {
  const [type, setType] = useState('')
  return (
    <div className="conTutGetUser">
      <span dangerouslySetInnerHTML={{ __html: strings.step1GetUser }} />
      <div className="viewImage">
        <div className="view_btn_store_tut">
          <span>Cài App: Chọn store tương ứng để cài trên điện thoại của bạn</span>
          <div className="market-btn apple-btn" role="button" onClick={onGoAppleStore}>
            <span className="market-button-title">{strings.App_Store}</span>
          </div>
          <p className={type === typeQr.android ? "textActive" : ''} onClick={() => setType(type === typeQr.android ? '' : typeQr.android)}>QR Android</p>
          {type === typeQr.android && <div className="viewQrTut">
            <QRCode
              size={500}
              style={{ height: "100%", width: "100%" }}
              value={linkAndroid}
              viewBox={`0 0 150 150`}
            />
          </div>}
          <div className="market-btn google-btn" role="button" onClick={onGoGoogleStore}>
            <span className="market-button-title">{strings.Google_Play}</span>
          </div>
          <p className={type === typeQr.ios ? "textActive" : ''}  onClick={() => setType(type === typeQr.ios ? '' : typeQr.ios)}>QR IOS</p>
          {type === typeQr.ios && <div className="viewQrTut">
            <QRCode
              size={500}
              style={{ height: "100%", width: "100%" }}
              value={linkIos}
              viewBox={`0 0 150 150`}
            />
          </div>}
        </div>
        <div>{'-->'}</div>
        <img alt={'b1-1'} src={'./payment/b1-1.jpg'} />
        <div>{'-->'}</div>
        <img alt={'b1-2'} src={'./payment/b1-2.jpg'} />
      </div>
      <span dangerouslySetInnerHTML={{ __html: strings.step2GetUser }} />
      <div className="viewImage">
        <img alt={'b1-1'} src={'./payment/b2.jpg'} />
      </div>
      <div className="closeTut" onClick={onClose}>
        <span>Đóng hướng dẫn</span>
      </div>
    </div>
  );
}

export default TutGetUserId;
