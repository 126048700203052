export function formatNumberWithSpaces(number, format = ' ') {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, format);
}

export function copyToClipboardFallback(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed'; // Đảm bảo không gây ảnh hưởng tới giao diện
    textArea.style.opacity = 0; // Ẩn text area
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand('copy');
        console.log(successful ? 'Đoạn text đã được copy!' : 'Copy không thành công.');
    } catch (err) {
        console.error('Không thể copy text:', err);
    }

    document.body.removeChild(textArea);
}

export function amountDiscount(amount, discount = 0.05) {
    return `${formatNumberWithSpaces(amount - (amount * discount), '.')} đ`
}
export function amountDiscountNumber(amount, discount = 0.05) {
    return amount - (amount * discount)
}
