import React, { useState, useMemo, useEffect } from 'react';
import { getDoc, doc, setDoc, getFirestore, updateDoc } from 'firebase/firestore/lite';
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import { initializeApp } from "firebase/app";
import { useSearchParams, useNavigate } from "react-router-dom";
import config from "../../config";
import { getAffData } from "../../lib/helper";
import { amountDiscount } from "../../lib/text";

import './styles.css';

const affLink = config.affLink

function AffDashboard(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [affs, setAffs] = useState([]);

  const affId = searchParams.get("aid") || ""
  const getAffDataById = (_affId) => {
    console.log('affLink + _affId', affLink + _affId)
    getAffData(affLink + _affId + "?limit=100", (res) => {
      console.log()
      setAffs(res)
    })
  }

  useEffect(() => {
    if (affId) {
      getAffDataById(affId)
    }
  }, [affId])

  console.log('affsaffs', affs)

  return (
    <div className="AffDashboard">
      <div class="container">
        <h2>Affiliate id: {affId}</h2>
        <p>Thống kê doanh số Affiliate</p>
        <table class="table">
          <thead>
            <tr>
              <th>Stt</th>
              <th>Tên gói Pro</th>
              <th>Giá nâng cấp</th>
              <th>Giá Affiliate nhận (15%)</th>
              <th>User Id mua</th>
              <th>Ngày mua</th>
              <th>Đã thanh toán cho Aff</th>
            </tr>
          </thead>
          <tbody>
            {affs.map((res, index) => {
              return <tr>
                <td>{index + 1}</td>
                <td>{res.productId}</td>
                <td>{amountDiscount(res.transferAmount, 0)}</td>
                <td>{amountDiscount(res.transferAmount, 0.85)}</td>
                <td>{res.userId}</td>
                <td>{res.transactionDate}</td>
                <td>{res.paid ? 'Rồi' : 'chưa'}</td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AffDashboard;
